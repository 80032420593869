<template>
  <div class="contatos">
    <div class="banner">
      <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
    </div>
    <div class="title">
        <h1>Contato</h1>
        <p>Sua opinião faz parte do nosso sucesso</p>
    </div>
    <form class="contato" ref="formContato">
        <input type="text" class="form-control" name="name" placeholder="Nome" v-model.trim="name">
        <input type="email" class="form-control" name="email" placeholder="E-mail" v-model.trim="email">
        <select class="form-control form-select" name="subject" aria-label="assunto" v-model="subject">
          <option selected disabled hidden>Assunto</option>
          <option value="Reclamação/Elogio">Reclamação/Elogio</option>
          <option value="Compras Administrativo">Compras Administrativo</option>
          <option value="Outros">Outros</option>
        </select>
        <textarea class="form-control" rows="5"  name="message" placeholder="Sua mensagem" v-model.trim="message"></textarea>
    </form>
    <div class="submit_button">
      <button type="submit" class="btn" @click="sendMsg()">Enviar</button>
    </div>
    <div class="submit_button">
      <a type="button" class="btn btn-md" href="https://forms.gle/AP5nwZbP5HugtChF6" target=”_blank” role="button" id="trabalhe">Trabalhe Conosco!</a>
    </div>
    <div class="banner2">      
      <img class="banner-detailimg3" :src="require(`@/assets/images/banner_detail3.webp`)" />
    </div>
    <div class="to_top">
      <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
    </div>
    <div class="modal" tabindex="-1" id="sendModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body">
            <p>Obrigado por sua mensagem!</p>
          </div>
        </div>
      </div>
    </div>
    <button hidden type="button" ref="modalBtn" data-bs-toggle="modal" data-bs-target="#sendModal">obrigado</button>
  </div>

</template>

<script>
import emailjs from 'emailjs-com';
import { useHead } from '@unhead/vue'

export default {
  name: 'Contato',
  setup() {
    useHead({
      title: 'Contato'
    })
  },
  data() {
      return {
          name: '',
          email: '',
          subject: 'Assunto',
          message: ''
      }
  },
  methods: {
      sendMsg() {
        try {
          emailjs.sendForm('service_contatodalena', 'template_contatodalena', this.$refs.formContato,
          'XIeQmHMymb5q37sii', {
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
          })
              // Reset form field
          this.name = '';
          this.email = '';
          this.subject = 'Assunto';
          this.message = '';
          this.$refs.modalBtn.click();

        } catch(error) {
            console.log({error})
        }

      },
      login () {
        this.$gtag.event('login', { method: 'Google' })
      }
  }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }

    // BANNER

  .banner-detailimg1 {
    width: 18%;
    position: absolute;
    top: 100px;
  }
  // title
  .title {
    text-align: center;
    margin: 30px 0 65px 0;
    line-height: 0.8;
  }

  h1 {
    font-family: 'Lora';
    color: #77321C;
    font-size: 32px;
    font-style: italic;
  }

  p {
    font-family: 'Lora';
    color: #77321C;
    font-size: 20px;
    font-style: italic;
  }

  // contato
  .contato>input,.contato>select {
    margin-bottom: 10px;
  }
  .contato {
    margin: 20px 300px 20px 300px;
    display: flex;
    flex-direction: column;
  }

  .name {
    display: flex;
    flex-direction: row;
  }

  // botão envio
  .submit_button {
    display: flex;
    justify-content: center;
  }
  .submit_button>button {
        background-color: #77321C;
        color: #F9B4A4;
        font-family: 'GoldplayAlt';
        width: 160px;
        border-radius: 40px;
        text-align: center;
        --bs-btn-font-size: 1.2rem;
        --bs-btn-font-style: normal;
    }

  // banner baixo

  .banner-detailimg3 {
    height: 260px;
    margin-left: auto;
  }
  .to_top {
    display: none;
  }


  // MODAL
  .modal {
    margin-top: 300px;
  }
  .btn-close {
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
  }

  .modal-body {
    display: flex;
    justify-content: center;
  }

  .modal-body > p {
    font-style: normal;
    font-weight: bold;
  }

  #trabalhe {
    background-color: #F9B4A4;
    color: #77321C;
    font-family: 'Lora';
    width: 220px;
    margin-top: 40px;
    border-radius: 40px;
    --bs-btn-font-size: 1.2rem;
    --bs-btn-font-weight: bold;
  }

  @media (max-width: 767px) {
    .banner-detailimg1 {
      top: 70px;
    }
    .contato {
      margin: 20px 26px 20px 26px;
    }

    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }

    .submit_button {
      justify-content: flex-end;
      margin-right: 30px;
    }

    .modal {
      margin-top: 50%;
    }
  }
</style>
